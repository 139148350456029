/* Center the main container and add padding */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

/* Style tabs */
.tabs {
  margin-bottom: 20px;
}

.tabs button {
  padding: 12px 24px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f1f1f1;
  cursor: pointer;
  margin: 0 10px;
  font-size: 16px;
}

.tabs button.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

/* Style the tab content with fixed size */
.tab-content {
  width: 100%;
  max-width: 500px;
  height: 450px; /* Fixed height */
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  overflow: auto; /* Handle overflow */
  display: flex;
  flex-direction: column;
}

/* Style form elements */
form div {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
}

input {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  font-size: 16px;
}

button {
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
}

/* Style the result section */
.result {
  margin-top: 20px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sign-out-button {
  margin: 20px;
  align-self: center; /* Center the button horizontally */
}

.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}